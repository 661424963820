import React from 'react';

const PdfViewer = ({ restaurantId }) => {
  // Construct the PDF URL by replacing 'poer.pdf' with '{restaurantId}.pdf'
  const pdfUrl = `https://mateza-cloud-storage.ams3.digitaloceanspaces.com/menu/pdf/${restaurantId}.pdf`;

  const iframeStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 'none'
  };

  return (
    <iframe 
      src={pdfUrl} 
      title="Menu PDF" 
      style={iframeStyle} 
    />
  );
};

export default PdfViewer;
