import React, { useEffect, useState } from 'react';
import InitPage from './Pages/Loading/InitPage.js';
import PdfViewer from './Pages/PdfViewer.js';

function App() {
  const params = new URLSearchParams(window.location.search);
  const loadingParam = params.get('loading') !== 'false';
  const [showInitPage, setShowInitPage] = useState(loadingParam);

  const restaurantName = params.get('restaurantName') || '';
  const restaurantId = params.get('restaurantId') || '';

  // Set globally for easy access
  window.restaurantName = restaurantName;
  window.restaurantId = restaurantId;

  // Set the document title globally
  useEffect(() => {
    document.title = `Menu ${restaurantName && restaurantName.trim() !== "" ? restaurantName : "dit restaurant"}`;
  }, [restaurantName]);

  const handleInitComplete = () => {
    setShowInitPage(false);
  };

  return (
    <>
      {showInitPage && <InitPage onInitComplete={handleInitComplete} />}
      <PdfViewer restaurantId={restaurantId} />
    </>
  );
}

export default App;
